import React from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenu.scss";
import { MGILeftMenuLink } from "../MGILeftMenuLink/MGILeftMenuLink";
import { MGILeftMenuDarkWhite } from "../MGILeftMenu/MGILeftMenuDarkWhite";
import { MGILeftMenuSeparation } from "../MGILeftMenu/MGILeftMenuSeparation";
import logoMGIPredict from "../assets/Logo_Predict.svg";
import logoMGIRegister from "../assets/logoregister.svg";
import logoMGITagGenerator from "../assets/Logo_Taggenerator.svg";
import logoMGIDashboard from "../assets/Logo-Dashboard.svg";
import logoMGICostCalculator from "../assets/Logo_Costcalculator.svg";
import logoHeadboard from "../assets/Logo_Headboard.svg";
import logoLicenses from "../assets/logomgilicences.svg";
import logoequipment from "../assets/logomgiequipment.svg";
import logomgilittle from "../assets/logomgilittle.svg";
import logoMGIDashboardhb from "../assets/logos/dashwhiote.svg";
import logoMGIPredicthb from "../assets/logos/predwhite.svg";
import { isharrisbruno } from "./checkharrisbruno";
import { Timezone, TimezoneType } from "./Timezone";

export interface MGILeftMenupProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // project name
  project: string;
  // items
  linkList: MenuList[];
  // switch theme
  switchTheme: boolean;
  // members
  members?: { label: string; url: string };
  // Support
  support?: { label: string; url: string };

  // timezone
  timezone?: TimezoneType;
  // Documentation dashboard

  // actions
  isVisibleMenu?: boolean;
  onClick?: (checkvisiblemenu: boolean) => void;
  currentUrl?: string;
}
export interface MenuList {
  linkleftmenu: string;
  svg: any;
  label: string;
  url: string;
  refresh?: boolean;
  onclick?: (e?: any) => void;
  position: "top" | "bottom";
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenu = ({
  styleClass = "primary",
  theme,
  project,
  linkList,
  switchTheme = false,
  timezone,
  support,
  members,
  currentUrl,
  onClick,
  isVisibleMenu,
  ...props
}: MGILeftMenupProps) => {
  const mode = "storybook-leftmenu--" + styleClass;
  const logo =
    project === "predict" && !isharrisbruno()
      ? logoMGIPredict
      : project === "predict" && isharrisbruno()
      ? logoMGIPredicthb
      : project === "dashboard" && !isharrisbruno()
      ? logoMGIDashboard
      : project === "dashboard" && isharrisbruno()
      ? logoMGIDashboardhb
      : project === "taggenerator"
      ? logoMGITagGenerator
      : project === "cost calculator"
      ? logoMGICostCalculator
      : project === "headboard"
      ? logoHeadboard
      : project === "mgiequipment"
      ? logoequipment
      : project === "register"
      ? logoMGIRegister
      : project === "licenses"
      ? logoLicenses
      : logoMGIPredict;

  return (
    <>
      <div className="btnmenu" onClick={() => onClick && onClick(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5f6368"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      </div>
      <div
        data-theme={theme ? theme : "light"}
        className={[
          "storybook-leftmenu",
          isVisibleMenu ? "menuopen" : "menuclose",
          mode,
        ].join(" ")}
        {...props}
      >
        <div className="menuclosebtn" onClick={() => onClick && onClick(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </div>
        <div className="storybook-leftmenu-container">
          <div className="logocontainer">
            <div
              className={
                project === "dashboard" ? "logomgiDashboard" : "logomgi"
              }
            >
              <img src={logo} alt="Logo mgi connect" />
            </div>
          </div>
          <div className="container_list">
            <div className="list1">
              <ul>
                {linkList
                  .filter((link) => link.position === "top")
                  .map((link, i) => {
                    return link.linkleftmenu === "separation" ? (
                      <li key={i}>
                        <MGILeftMenuSeparation />
                      </li>
                    ) : (
                      <li key={i}>
                        <div
                          className="lilith"
                          onClick={() => onClick && onClick(false)}
                        >
                          <MGILeftMenuLink
                            theme={theme ? theme : "light"}
                            label={link.label}
                            project={project}
                            svg={link.svg}
                            url={link.url}
                            currentUrl={currentUrl}
                            refresh={
                              link.refresh && link.refresh === true
                                ? true
                                : false
                            }
                            onClick={link.onclick}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="list2">
              <ul>
                {linkList
                  .filter((link) => link.position === "bottom")
                  .map((link, i) => {
                    return link.linkleftmenu === "separation" ? (
                      <li key={i}>
                        <MGILeftMenuSeparation />
                      </li>
                    ) : link.linkleftmenu === "timezone" ? (
                      <div
                        data-theme={theme ? theme : "light"}
                        // className={["storybook-leftmenu", mode].join(" ")}
                        // {...props}
                      >
                        <div className="whitedarkmode">
                          {timezone && (
                            <Timezone
                              theme={theme ? theme : "light"}
                              timezone={timezone}
                            />
                          )}

                          {switchTheme && (
                            <li key={linkList.length + 2}>
                              <MGILeftMenuDarkWhite
                                theme={theme ? theme : "light"}
                                linkList={[]}
                              />
                            </li>
                          )}
                        </div>
                      </div>
                    ) : (
                      <li key={i}>
                        <div
                          className="lilith"
                          onClick={() => onClick && onClick(false)}
                        >
                          <MGILeftMenuLink
                            theme={theme ? theme : "light"}
                            label={link.label}
                            project={project}
                            svg={link.svg}
                            url={link.url}
                            currentUrl={currentUrl}
                            refresh={
                              link.refresh && link.refresh === true
                                ? true
                                : false
                            }
                            onClick={link.onclick}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="logomgicontainer">
            <div className="logomgibot">
              <img src={logomgilittle} alt="MGI" />
              <p>Digital Technology </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
