import React from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenu.scss";

export interface MGILeftMenupProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // items
  linkList: { svg: any; label: string; url: string }[];
  // actions
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenuDarkWhite = ({
  styleClass = "primary",
  theme,
}: // linkList,
// ...props
MGILeftMenupProps) => {
  const mode = "storybook-leftmenu--" + styleClass;
  return (
    <li className="li1" key={1}>
      <div
        className="lilith"

        // onClick={handleUpdateUserTheme}
      >
        {theme === "light" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <path
              id="dark_mode_FILL0_wght400_GRAD0_opsz48"
              d="M24,42a17.359,17.359,0,0,1-12.75-5.25A17.359,17.359,0,0,1,6,24a17.359,17.359,0,0,1,5.25-12.75A17.359,17.359,0,0,1,24,6q.4,0,.85.025T26,6.1a10.923,10.923,0,0,0-2.8,3.95,12.512,12.512,0,0,0-1,4.95,10.415,10.415,0,0,0,3.15,7.65A10.415,10.415,0,0,0,33,25.8a13.393,13.393,0,0,0,4.95-.925A11.052,11.052,0,0,0,41.9,22.3q.05.6.075.975T42,24a17.359,17.359,0,0,1-5.25,12.75A17.359,17.359,0,0,1,24,42Zm0-3a14.382,14.382,0,0,0,9.5-3.375A14.168,14.168,0,0,0,38.55,27.7a12.5,12.5,0,0,1-2.675.825A15.138,15.138,0,0,1,33,28.8a13.308,13.308,0,0,1-9.775-4.025A13.308,13.308,0,0,1,19.2,15a14.438,14.438,0,0,1,.25-2.575,18.782,18.782,0,0,1,.9-3.125,15.23,15.23,0,0,0-8.125,5.475A14.606,14.606,0,0,0,9,24a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,39ZM23.8,24.15Z"
              transform="translate(-6 -6)"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <path
              id="dark_mode_FILL0_wght400_GRAD0_opsz48"
              d="M24,42a17.359,17.359,0,0,1-12.75-5.25A17.359,17.359,0,0,1,6,24a17.359,17.359,0,0,1,5.25-12.75A17.359,17.359,0,0,1,24,6q.4,0,.85.025T26,6.1a10.923,10.923,0,0,0-2.8,3.95,12.512,12.512,0,0,0-1,4.95,10.415,10.415,0,0,0,3.15,7.65A10.415,10.415,0,0,0,33,25.8a13.393,13.393,0,0,0,4.95-.925A11.052,11.052,0,0,0,41.9,22.3q.05.6.075.975T42,24a17.359,17.359,0,0,1-5.25,12.75A17.359,17.359,0,0,1,24,42Zm0-3a14.382,14.382,0,0,0,9.5-3.375A14.168,14.168,0,0,0,38.55,27.7a12.5,12.5,0,0,1-2.675.825A15.138,15.138,0,0,1,33,28.8a13.308,13.308,0,0,1-9.775-4.025A13.308,13.308,0,0,1,19.2,15a14.438,14.438,0,0,1,.25-2.575,18.782,18.782,0,0,1,.9-3.125,15.23,15.23,0,0,0-8.125,5.475A14.606,14.606,0,0,0,9,24a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,39ZM23.8,24.15Z"
              transform="translate(-6 -6)"
            />
          </svg>
        )}

        <div className="btn_select">
          {/* <p>{theme === "light" ? "darkmode" : "whitemode"}</p> */}
          <div
            // className="bkgbtn btnwhite"
            className={theme === "light" ? "bkgbtn btnwhite" : "bkgbtn"}
          >
            <div className="btninterieur"></div>
          </div>
        </div>
      </div>
    </li>
  );
};
