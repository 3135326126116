import React from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenu.scss";

export type TimezoneType = {
  timezoneEquipmentText: string;
  timezoneEquipment: TimezoneDetails;
  timezoneUserText: string;
  timezoneUser: TimezoneDetails;
  equipmentHasTimezone: boolean;
};

type TimezoneDetails = { name: string; utc: string; time: string };

/**
 * Primary UI component for user interaction
 */
export const Timezone = ({
  styleClass = "primary",
  theme,
  onClick,
  timezone,
  ...props
}: {
  styleClass?: string;
  theme?: Theme;
  onClick?: () => void;
  timezone: TimezoneType;
}) => {
  return (
    <div className="timezonecontent">
      <div className="timezone">
        {timezone.equipmentHasTimezone && (
          <div className="timezoneequip">
            <div className="timezonetitle">
              <p>Timezone</p>
              <p>{timezone.timezoneEquipmentText}</p>
            </div>
            <div className="timezoneinfos">
              <p>{timezone.timezoneEquipment.name}</p>
              <p>{timezone.timezoneEquipment.utc}</p>
            </div>
          </div>
        )}
        <div className="timezoneuser">
          <div className="timezonetitle">
            <p>Timezone</p>
            <p>{timezone.timezoneUserText}</p>
          </div>
          <div className="timezoneinfos">
            <p>{timezone.timezoneUser.name}</p>
            <p>{timezone.timezoneUser.utc}</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
