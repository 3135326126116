import React from "react";
import { Theme } from "../styles/types";
import "./MGIDatePicker.scss";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "moment/dist/locale/fr";

const SELECT_OPTION = [
  "allJobs",
  "last48",
  "currentWeek",
  "currentMonth",
] as const;
type SelectOptions = typeof SELECT_OPTION[number] | "custom";
// type SelectOptionSensors = Exclude<SelectOptionJobs, "allJobs">;

export interface MGIDatePickerProps {
  theme?: Theme;

  // datepicker
  i18n: any;
  type: string;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  focused: any;
  setFocused: React.Dispatch<any>;
  translatedOptions: string[];
  selectedOption: SelectOptions;
  setSelectedOption: React.Dispatch<React.SetStateAction<SelectOptions>>;
  showSelectOption: boolean;
  handleSelectOption: (e: SelectOptions) => void;
  renderDayContents: (
    momentInstance: moment.Moment,
    modifiers: Set<string>
  ) => JSX.Element;

  // class name
  styleClass?: string;
  // project name
  project: string;
  // items
  linkList: { linkleftmenu: string; svg: any; label: string; url: string }[];
  // switch theme
  switchTheme: boolean;
  // Documentation dashboard
  docdashboard?: { label: string; url: string };
  // Centre d'alertes
  machineType?: string;
  // alert link if it exists
  alerteLink?: { label: string; onClick: any };
  // actions
  onClick?: () => void;
  placeholderStart: string;
  placeholderEnd: string;
}

/**
 * Primary UI component for user interaction
 */
export const MGIDatePicker = ({
  styleClass = "primary",
  theme,
  i18n,
  type,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  focused,
  setFocused,
  translatedOptions,
  selectedOption,
  setSelectedOption,
  handleSelectOption,
  renderDayContents,
  project,
  linkList,
  switchTheme = false,
  machineType = "",
  alerteLink,
  docdashboard,
  showSelectOption,
  placeholderStart,
  placeholderEnd,
  ...props
}: MGIDatePickerProps) => {
  const renderDayContentsMonth = React.useCallback(
    (momentInstance: moment.Moment) => {
      return <>{momentInstance.locale(i18n.language).format("MMMM YY")}</>;
    },
    [i18n.language]
  );

  return (
    <div data-theme={theme ? theme : "dark"} {...props}>
      <div className={"datetimepickerzone"}>
        {showSelectOption && (
          <select
            onChange={(e) =>
              handleSelectOption(e.target.value as SelectOptions)
            }
            value={selectedOption}
          >
            {(type === "jobs" ? SELECT_OPTION : SELECT_OPTION.slice(1)).map(
              (e, i) => (
                <option value={e}>{translatedOptions[i]}</option>
              )
            )}
            {selectedOption === "custom" && (
              <option value={"custom"}>{"CUSTOM"}</option>
            )}
          </select>
        )}
        <div className={"datePickSelect"}>
          {
            <DateRangePicker
              endDate={endDate ? endDate.locale(i18n.language) : null}
              startDate={startDate ? startDate.locale(i18n.language) : null}
              endDateId="end"
              focusedInput={focused}
              onDatesChange={(arg) => {
                setStartDate(arg.startDate?.locale(i18n.language));
                setEndDate(arg.endDate?.locale(i18n.language));
                setSelectedOption("custom");
              }}
              onFocusChange={(t) => {
                setFocused(t);
              }}
              minimumNights={0}
              startDateId="start"
              enableOutsideDays={true}
              isOutsideRange={() => false}
              renderDayContents={(a, i) => <p> {renderDayContents(a, i)} </p>}
              keepOpenOnDateSelect
              firstDayOfWeek={1}
              startDatePlaceholderText={placeholderStart}
              endDatePlaceholderText={placeholderEnd}
              displayFormat="DD MMMM YYYY"
              small
              isDayBlocked={(e) => e.isAfter(moment(), "day")}
              renderMonthElement={(a) => renderDayContentsMonth(a.month)}
            />
          }
        </div>
      </div>
    </div>
  );
};
